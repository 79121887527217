.footer{
    background-color: rgb(39, 39, 39);
    min-height: 20vh;
    color: white; 
}
.content{
    margin-top: 10vh;
    padding: 30px;
}
.footer p{
    color: grey; 
}