.desktopParent{
  display: flex;
}

.grid{
  top: 50%;
  transform: translate(0%, 50%); 
  z-index: 4;
  width: 100%;
  height: 100%; 
  padding-left: 0; 
  padding-right: 0; 
  position: relative;
}
.action{
  max-height: 20vh;
}
.background{
  width: 100%;
  z-index: 2;
  position: absolute;
}