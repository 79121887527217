.blobContainer{
  position: relative;
  width: 15vw; 
  transform: translate(5vw, 3vw);
}
.blobContainer p{
  font-size: x-large;
}

.blobText{
  position: absolute;
  color: white; 
  top: 50%; 
  left: 2%; 
  z-index: 3;
  transform: translate(0%, 20%);
}
.squareA{
  position: absolute;
  background-color: purple;
  width: 10vw; 
  height: 10vw;
  opacity: 0.5;
}
.squareB{
  position: absolute;
  background-color: blueviolet;
  width: 10vw; 
  height: 10vw;
  opacity: 0.5;
  transform: translate(3.75vw, 3.75vw);
}
