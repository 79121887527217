.backdrop{
    background-color: rgb(70, 100, 109);
    position: relative;
    width: auto; 
    color: white; 
}

.aboutTitle{
    padding: 20px; 
}
.headerColor{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    background-color:  rgb(58, 76, 82);
}

.aboutOptions{
    padding: 10px; 
    font-size: x-large;
    background-color:  rgb(49, 66, 71);;
    left: initial;
    height: 100%;
}

.text{
    padding: 30px;
    color:white; 
    text-align: start; 
    max-width: 800px;
}