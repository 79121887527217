.sectionHeader{
    background-color: rgb(22, 22, 22);
    position: relative;
}
.headerImage{
    width: 100%;
    height: 100%;
    z-index: 3;
}
.sectionTitle{
    position: absolute;
    color: rgb(255, 255, 255);
    text-align: center;
    top: 50%;
    left: 0; 
    width: 100%; 
    font-size: 5vh;
    z-index: 4;
}
.fog{
    position: absolute; /* Sit on top of the page content */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.733); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    transition: 1s;
}
.fog:hover{
    opacity: 0.2;
    transition: 1s; 
}
.collapse{
    position: absolute;
    color: rgb(255, 255, 255);
    text-align: initial;
    top: 0%;
    width: 100%; 
    font-size: 22pt;
    z-index: 1;
}

