.tempSection{
  color: white; 
  padding-top: 10%;
  padding-right: 10%;
  position: relative;
  z-index: 100;
}
a:link{
  color: white;
}
.image{
  position: absolute;
  top: 100vh;
  width: 100%; 
  left: 0;
  z-index: -100;
  background-color: #282c34;
}
.layer{
  background-color: #282c34;
  position: absolute;
  opacity: 0.6;
  /* top: 100vh; */
  left: 0;
  width: 100%;
  height: 100%;
}
.backgroundImage{
  min-height: 100vh;
}
h2{
  font-size: 400pt;
}
p{
  font-size: larger;
}